// Keen Theme plugins
window.KTUtil = require('@keen/src/js/components/util.js');
window.KTApp = require('@keen/src/js/components/app.js');
window.KTComponents = require('@keen/src/js/components/_init.js');
window.KTEventHandler = require('@keen/src/js/components/event-handler.js');
window.KTBlockUI = require('@keen/src/js/components/blockui.js');
window.KTCookie = require('@keen/src/js/components/cookie.js');
window.KTDialer = require('@keen/src/js/components/dialer.js');
window.KTDrawer = require('@keen/src/js/components/drawer.js');
window.KTFeedback = require('@keen/src/js/components/feedback.js');
window.KTImageInput = require('@keen/src/js/components/image-input.js');
window.KTMenu = require('@keen/src/js/components/menu.js');
window.KTPasswordMeter = require('@keen/src/js/components/password-meter.js');
window.KTScroll = require('@keen/src/js/components/scroll.js');
window.KTScrolltop = require('@keen/src/js/components/scrolltop.js');
window.KTSearch = require('@keen/src/js/components/search.js');
window.KTStepper = require('@keen/src/js/components/stepper.js');
window.KTSticky = require('@keen/src/js/components/sticky.js');
window.KTSwapper = require('@keen/src/js/components/swapper.js');
window.KTToggle = require('@keen/src/js/components/toggle.js');
window.KTToaster = require('@keen/src/plugins/toastr/build/toastr.min.js');

// Layout base js
// window.KTAppLayoutBuilder = require('@keen/src/js/layout/builder.js');
// window.KTLayoutSearch = require('@keen/src/js/layout/search.js');
// window.KTThemeMode = require('@keen/src/js/layout/theme-mode.js');
// window.KTThemeModeUser = require('@keen/src/js/layout/theme-mode-user.js');
window.KTAppSidebar = require('@keen/src/js/layout/sidebar.js');